<template>
  <div class="container">
    <div class="logoTitle colorBlack">
      <p class="main">G멤버스 <strong>쿠폰 전환</strong></p>
    </div>
    <p class="lineTitle">G멤버스 쿠폰 전환</p>
    <div class="tileBox">
        <div class="formGroup">
          <div class="label">현재 포인트</div>
          <div class="value">
            <input type="text" class="input textLeft" disabled :placeholder="`${point}포인트`">
          </div>
        </div>

        <div class="formGroup">
          <div class="label">쿠폰 전환</div>
          <div class="value">
            <select class="input select"
                    @change="onChange"
                    v-model="couponPrice">
              <option value="0">쿠폰을 선택하세요</option>
              <option v-for="(num, i) in couponList" :key="i" :value="num">티켓 {{ comma(num) }}원 쿠폰</option>
            </select>
          </div>
        </div>
      </div>
    <div class="mt30 mb20">
      <button type="button" class="btn full" @click="init">쿠폰 전환</button>
    </div>

    <div class="mb45">
      <router-link :to="{name: 'pointHistory'}" type="button" class="btn full black">포인트 내역</router-link>
    </div>
    <p class="lineTitle">쿠폰 전환 안내사항</p>
    <p class="light fz14 mb20 colorSemiBlack">
      - 쿠폰 전환 시, 다시 '포인트로 전환 불가'합니다.<br>
      - 전환된 쿠폰은 '쿠폰조회'에서 확인 가능합니다.<br>
      - 쿠폰 사용 기한은 매년 소멸되며, 예외로 2023시즌에 발급된 쿠폰은 2024시즌까지 사용 가능합니다.</p>
  </div>
</template>
<script>
import api from '@/api'
import regExp from '@/common/regExp'
export default {
  name: 'PointExchange',
  computed: {
    list () {
      const coupon = this.couponList.map(el => regExp.comma(el))
      return coupon
    }
  },
  created () {
    // eslint-disable-next-line no-return-assign
    api.get('/user').then(res => {
      const point = res.data.data.point
      this.point = regExp.comma(point)
    })
  },
  data () {
    return {
      point: 0,
      couponList: [3000, 5000],
      couponPrice: 0,
      isOpen: false
    }
  },
  methods: {
    comma (num) {
      return regExp.comma(num)
    },
    init () {
      if (this.couponPrice === 0) {
        return alert('전환할 쿠폰을 선택하세요.')
      }
      api.post(`/user/coupon?amount=${this.couponPrice}`)
        .then(() => {
          if (confirm('보유하신 포인트를 쿠폰으로 전환하시겠습니까?')) {
            return this.$router.push({name: 'pointHistory'})
          }
        })
        .catch(err => {
          if (err.response.data.type === 'point') {
            return alert(`${err.response.data.message}`)
          }
          if (err.response.data.type === 'agree') {
            if (confirm(`${err.response.data.message}`)) {
              return this.$router.push({name: 'myPage'})
            }
          }

          if (err.response.data.type === 'coupon') {
            return alert(`${err.response.data.message}`)
          }
        })
    },
    onChange () {
      document.activeElement.blur()
    }
  }
}
</script>
