<template>
  <div class="container">
    <div class="historyList mb30">
      <div class="logoTitle colorBlack">
        <p class="main">G멤버스 <strong>시즌권 구매</strong></p>
      </div>
      <ul>
      </ul>
      <div class="mb20">
        <button type="button" class="btn full mb20" @click="purchase" >시즌권 구매</button>
        <button type="button" class="btn full mb20 black" @click="pointConversion" >시즌권 구매 확인/취소</button>
      </div>
      <img src="@/assets/img/season/gmembers_2025_seasonticket.png" alt=""/>
      <!--      <p class="mt20 fz14 light colorSemiBlack">-->
      <!--      </p>-->
    </div>
  </div>
</template>
<script>
import api from '@/api/index'
import {reservePopup, mypagePopup} from '@/library/ticketlink'
import router from '@/router'
export default {
  name: 'BuyMembership',
  data () {
    return {
      filter: '1month', // 초기 필터 값 설정
      filterOptions: [
        {id: '1month', name: '1개월'},
        {id: '3months', name: '3개월'},
        {id: '6months', name: '6개월'},
        {id: 'thisYear', name: '올해'},
        {id: 'lastYear', name: '지난해'}
      ],
      type: '',
      list: [],
      term: 1,
      encrypted_id: ''
    }
  },
  methods: {
    setFilter (duration) {
      this.filter = duration
    },
    fetchData () {
    },
    purchase () {
      const data = localStorage.getItem('userData')
      if (!data) {
        const currentQuery = this.$route.fullPath
        this.$router.push({
          path: '/login',
          query: { redirect: currentQuery }
        })
        return
      }
      api.get('/user/ticketlink_id')
        .then(res => {
          this.encrypted_id = res.data.data.id
          if (this.encrypted_id !== '') {
            const url = reservePopup(this.encrypted_id, 'pc')
            window.open(url, 'tl', 'width=1010, height=600, left=100, top=100, scrollbars=yes')
          }
          if (this.encrypted_id !== '') {
            const url = reservePopup(this.encrypted_id, 'pc')
            window.open(url, 'tl', 'width=1010, height=600, left=100, top=100, scrollbars=yes')
          }
        })
        .catch(err => console.log(err))
    },
    pointConversion () {
      const data = localStorage.getItem('userData')
      api.get('/user/ticketlink_id')
        .then(res => {
          this.encrypted_id = res.data.data.id
          if (this.encrypted_id !== '') {
            const url = mypagePopup(this.encrypted_id, 'pc')
            window.open(url, '_blank')
          }
        })
        .catch(err => console.log(err))
    }
  }
}
</script>
